module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"qubtimetable - aplicatie de vizualizat si gestionat orare","short_name":"qubtimetable","description":"qub timetable este un sistem intuitiv si ușor de folosit, cu ajutorul căruia iți poți vizualiza si gestiona orarele. Acest sistem este destinat universităților, școlilor si liceelor, care își pot adăuga orarele pe site-ul timetable.qubuilder.com, iar studenții și elevii vor avea acces la acestea prin intermediul aplicației de mobil, qub timetable. Aplicația este disponibilă atât pe Android, cât și pe iOS.","start_url":"/","background_color":"#101628","theme_color":"#AE5E18","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","orientation":"portrait","lang":"ro","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"27a34e6b1de81fdd8e8c04c3432ba826"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#AE5E18","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"ro","configPath":"/opt/build/repo/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/react-i18next","i18nextOptions":{"ns":["translation","404","login","downloadIos","downloadOsx","info","timetable","crud","errors","applyInstitution"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-9ZQRMM8LD9","cookieName":"gatsby-gdpr-google","anonymize":true,"allowAdFeatures":true},"googleTagManager":{"trackingId":"G-9ZQRMM8LD9","cookieName":"gatsby-gdpr-google"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-vitals/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-207177421-1","metrics":["FID","TTFB","LCP","CLS","FCP"],"eventCategory":"Performance","includeInDevelopment":false,"debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
