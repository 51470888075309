import "./src/styles/global.css";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import { matchPath } from "@gatsbyjs/reach-router";

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(`This application has been updated. Reload to display the latest version?`);
  if (answer === true) {
    window.location.reload();
  }
};

function hideTawk() {
  if (typeof Tawk_API !== "undefined") {
    if (typeof Tawk_API.hideWidget === "function") {
      Tawk_API.hideWidget();
    }
    Tawk_API = Tawk_API || {};
    Tawk_API.onLoad = function () {
      Tawk_API.hideWidget();
    };
  }
}

function showTawk() {
  if (typeof Tawk_API !== "undefined") {
    if (typeof Tawk_API.showWidget === "function") {
      Tawk_API.showWidget();
    }
    Tawk_API = Tawk_API || {};
    Tawk_API.onLoad = function () {
      Tawk_API.showWidget();
    };
  }
}

const hidePaths = ["/timetable/*", "/app/*"];

export const onRouteUpdate = ({ location }) => {
  let matched = false;
  for (const path of hidePaths) {
    if (matchPath(path, location.pathname)) {
      hideTawk();
      matched = true;
      break;
    }
  }
  if (!matched) {
    showTawk();
  }
};
